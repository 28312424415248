<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <loading v-if="cargando" />
      <section class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="fecha_inicial">Fecha Inicial</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="fecha_inicial"
                  v-model="filters.fecha_inicial"
                  @input="validaFechas"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="fecha_final">Fecha Final</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="fecha_final"
                  v-model="filters.fecha_final"
                  @input="validaFechas"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <button
                type="button"
                @click="getInternos(), getNacionales(), getIndicadores()"
                class="btn alert-default-info mt-4"
                v-if="!$v.filters.$invalid"
              >
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
            <div class="col-sm-2">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Tep</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section class="content">
        <div class="container-fluid">
          <!-- Default box -->
          <div class="row">
            <div class="col-md-6">
              <div class="card p-3">
                <div class="card-header">
                  <div class="col-6">
                    <b class="card-title text-bold mb-0">INTERNOS</b>
                  </div>
                </div>
                <div
                  class="card-body table-responsive"
                  style="max-height: 1035px"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Buscar"
                        v-model="search"
                      />
                    </div>
                  </div>
                  <br />
                  <div
                    v-for="status in filterInternos"
                    :key="status.sitio_id"
                    class="card"
                  >
                    <div class="card-header p-1">
                      <b>{{ status.sitio }}</b>
                    </div>
                    <div class="card-body p-2">
                      <div class="row">
                        <span class="info-box-text col-md-3 text-center">
                          <p class="text-warning m-1">
                            <i class="fas fa-calendar"></i>
                            {{ status.programados }}
                          </p>
                          <p>Programados:</p>
                        </span>
                        <span class="info-box-text col-md-3 text-center">
                          <p class="text-danger m-1">
                            <i class="fas fa-user-clock"></i>
                            {{ status.pendientes }}
                          </p>
                          <p>Pendientes:</p>
                        </span>
                        <span class="info-box-text col-md-3 text-center">
                          <p class="text-info m-1">
                            <i class="fas fa-play-circle"></i>
                            {{ status.iniciados }}
                          </p>
                          <p>Iniciados:</p>
                        </span>
                        <span class="info-box-text col-md-3 text-center">
                          <p class="text-success m-1">
                            <i class="fas fa-check-circle"></i>
                            {{ status.finalizados }}
                          </p>
                          <p>Finalizados:</p>
                        </span>
                        <div class="col-md-2"></div>
                        <span class="info-box-text col-md-4 text-center">
                          <p class="text-primary m-1">
                            <i class="fas fa-hands-helping"></i>
                            {{ status.conciliados }}
                          </p>
                          <p>Conciliados:</p>
                        </span>
                        <span class="info-box-text col-md-4 text-center">
                          <p class="text-dark m-1">
                            <i class="fas fa-tachometer-alt"></i>
                            {{ status.km_recorridos }}
                          </p>
                          <p>Km Recorridos:</p>
                        </span>
                        <div class="col-md-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card p-3">
                <div class="card-header">
                  <div class="col-6">
                    <b class="mb-0">NACIONALES</b>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="info-box mb-3">
                        <span
                          class="
                            info-box-icon
                            alert-default-warning
                            elevation-2
                          "
                          ><i class="fas fa-laptop-house"></i
                        ></span>
                        <div class="info-box-content">
                          <span class="info-box-text">Programados</span>
                          <span
                            class="info-box-number"
                            style="font-size: 20px"
                            >{{ statusNacional.programados }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="info-box mb-3">
                        <span
                          class="
                            info-box-icon
                            alert-default-primary
                            elevation-2
                          "
                          ><i class="fas fa-users-cog"></i
                        ></span>
                        <div class="info-box-content">
                          <span class="info-box-text">Ejecución</span>
                          <span
                            class="info-box-number"
                            style="font-size: 20px"
                            >{{ statusNacional.ejecucion }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="info-box mb-3">
                        <span
                          class="info-box-icon alert-default-info elevation-2"
                          ><i class="fas fa-handshake"></i
                        ></span>
                        <div class="info-box-content">
                          <span class="info-box-text">Conciliados</span>
                          <span
                            class="info-box-number"
                            style="font-size: 20px"
                            >{{ statusNacional.conciliados }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card p-3">
                <div class="card-header">
                  <div class="col-6">
                    <b class="mb-0">INDICADORES</b>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="info-box mb-3 bg-navy">
                        <span class="info-box-icon"
                          ><i class="fas fa-users"></i
                        ></span>
                        <div class="info-box-content">
                          <span class="info-box-text"
                            >Personas Transportadas</span
                          >
                          <span class="info-box-number">{{
                            indicadores.pasajeros
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="info-box mb-3 bg-success">
                        <span class="info-box-icon"
                          ><i class="fas fa-truck-pickup"></i
                        ></span>
                        <div class="info-box-content">
                          <span class="info-box-text"
                            >Vehículos en Operación</span
                          >
                          <span class="info-box-number">{{
                            statusNacional.ejecucion
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="info-box mb-3 bg-danger">
                        <span class="info-box-icon"
                          ><i class="fas fa-chart-bar"></i
                        ></span>
                        <div class="info-box-content">
                          <span class="info-box-text"
                            >Promedio de Kms registrados</span
                          >
                          <span class="info-box-number">{{
                            indicadores.kmRecorridos
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="info-box mb-3 bg-info">
                        <span class="info-box-icon"
                          ><i class="fas fa-hourglass-half"></i
                        ></span>
                        <div class="info-box-content">
                          <span class="info-box-text">Horas Operativas</span>
                          <span class="info-box-number">{{
                            indicadores.horas
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-lg-7 pt-2">
                      <div class="small-box bg-light text-center">
                        <b style="font-size: auto" class="p-1"
                          >Reporte de Participación de Vehículos de la
                          Comunidad</b
                        >
                        <hr />
                        <pie-chart
                          :data="chartData"
                          :options="chartOptionsPie"
                        ></pie-chart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/Loading";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import moment from "moment";
import PieChart from "./PieChart.js";

export default {
  name: "DashboardTep",
  components: {
    Loading,
    PieChart,
  },
  data() {
    return {
      chartOptionsPie: {
        hoverBorderWidth: 10,
      },
      animation: {
        animateRotate: false,
        animateScale: true,
      },
      chartData: {
        labels: ["Comunidad", "Otros"],
        datasets: [
          {
            backgroundColor: ["#ff1c79", "#001871"],
            borderWidth: 2,
            hoverOffset: 4,
          },
        ],
      },
      cargando: false,
      filters: {
        fecha_inicial: "",
        fecha_final: "",
      },
      statusInterno: [],
      statusNacional: [],
      indicadores: [],
      search: "",
      hoy: moment().format("YYYY-MM-DD"),
    };
  },

  validations: {
    filters: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },

  methods: {
    async getIndex() {
      this.cargando = true;
      this.filters.fecha_inicial = this.hoy;
      this.filters.fecha_final = this.hoy;
      await this.getInternos();
      await this.getNacionales();
      await this.getIndicadores();
      this.cargando = false;
    },

    async getInternos() {
      let me = this;
      this.cargando = true;
      await axios
        .get("/api/tep/dashboardTep/graficaStatusInterno", {
          params: this.filters,
        })
        .then(async (response) => {
          me.statusInterno = response.data;
        });
    },

    async getNacionales() {
      let me = this;
      this.cargando = true;
      await axios
        .get("/api/tep/dashboardTep/graficaStatusNacional", {
          params: this.filters,
        })
        .then(async (response) => {
          me.statusNacional = response.data;
        });
    },

    async getIndicadores() {
      let me = this;
      this.cargando = true;
      await axios
        .get("/api/tep/dashboardTep/getGraficaIndicadores", {
          params: this.filters,
        })
        .then(async (response) => {
          me.indicadores = response.data;
          me.chartData.datasets[0].data[0] = me.indicadores.vhComunidad.comu;
          me.chartData.datasets[0].data[1] = me.indicadores.vhComunidad.ext;
          me.cargando = false;
        });
    },

    validaFechas() {
      if (this.filters.fecha_inicial && this.filters.fecha_final) {
        const fecha_menor = new Date(this.filters.fecha_inicial);
        const fecha_mayor = new Date(this.filters.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filters.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
  },

  computed: {
    filterInternos() {
      if (this.statusInterno) {
        return this.statusInterno.filter((det) => {
          const st = det.sitio.toLowerCase();
          const searchTermSt = this.search.toLowerCase();
          return st.includes(searchTermSt);
        });
      } else {
        return this.statusInterno;
      }
    },
  },

  async mounted() {
    await this.getIndex();
  },
};
</script>
